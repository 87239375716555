<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="1" tab="日程管理">
      <iss-classify />
    </a-tab-pane>
    <a-tab-pane key="2" tab="日程分类管理">
      <iss-schedule />
    </a-tab-pane>
  </a-tabs>
</template>
<!-- 这里tab暂时不用了  -->

<script>
import { reactive, toRefs } from 'vue';
import { Tabs } from 'ant-design-vue';
import issClassify from '../dashboard/components/classify';
import issSchedule from '../dashboard/components/schedule';

export default {
  components: {
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    issClassify,
    issSchedule,
  },
  setup() {
    // const route = useRoute();
    const state = reactive({
      activeKey: '1',
    });

    return {
      ...toRefs(state),
    };
  },
};
</script>
