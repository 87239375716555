<template>
  <div class="iss-main-grid">
    <div class="iss-search-wrap">
      <search-form :items="items" @fnSearch="handleFnSearch" />
    </div>
    <div class="iss-grid-wrap mt-16">
      <grid
        ref="gridRef"
        allow-selection
        :columns="columns"
        :code="$route.path"
        :url="url"
        :search="search"
        :btn-operation="btnOperation"
        :scroll="{ x: 1640, y: gridHeight }"
      >
        <template #receiveConsult="{ text }">
          <a-tag v-if="text === true" color="success">接受</a-tag>
          <a-tag v-else-if="text === false" color="error">不接受</a-tag>
          <span v-else></span>
        </template>
        <template #operation="{ record }">
          <operation :options="options" :record="record" />
        </template>
        <template #auditStatus="{ record }">
          <div v-if="record.auditStatus === 0">未审核</div>
          <div v-else-if="record.auditStatus === 1">审核通过</div>
          <div v-else-if="record.auditStatus === 2">审核不通过</div>
        </template>
        <!--        发布状态 -->
        <template #publishStatus="{ record }">
          <div v-if="record.publishStatus === 0">未发布</div>
          <div v-else-if="record.publishStatus === 1">已发布</div>
        </template>
        <template #isLive="{ record }">
          <div v-if="record.isLive === 0">没有</div>
          <div v-else-if="record.isLive === 1">有</div>
        </template>
        <!-- <template #meetingFormat="{ record }">
          <div v-if="record.meetingFormat === '线上'">线上</div>
          <div v-else-if="record.meetingFormat === '线下'">线下</div>
        </template> -->

        <template #gender="{ record }">
          <div class="iss-user">
            <a-tooltip title="查看议程" placement="top">
              <a-button
                type="primary"
                size="middle"
                shape="circle"
                ghost
                @click="handleClickViewMember(record)"
              >
                <template #icon><EyeOutlined /></template>
              </a-button>
            </a-tooltip>
            <span style="padding-left: 5px">{{ record.number }}</span>
          </div>
        </template>
        <template #isHome="{ record }">
          <div v-if="record.isHome == 1">是</div>
          <div v-else>否</div>
        </template>
      </grid>
    </div>
  </div>
  <iss-edit
    v-model:visible="visible"
    :init-value="activeItem"
    @fnOk="handleFnOkByEdit"
  />
</template>

<script>
import { reactive, ref, toRefs } from 'vue';
import { Tag, message } from 'ant-design-vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import SearchForm from '@/components/searchForm';
import Grid from '@/components/grid';
import Operation from '@/components/operation';
// import contactApi from '@/api/contact.js';
// import dictionaryApi from '@/api/dictionary.js';
import issEdit from './edit';
import { EyeOutlined } from '@ant-design/icons-vue';
import classifyApi from '@/api/classify';

export default {
  components: {
    SearchForm,
    Grid,
    Operation,
    ATag: Tag,
    issEdit,
    EyeOutlined,
  },
  setup() {
    const gridRef = ref();
    const store = useStore();
    const state = reactive({
      visible: false,
      search: {},
      // TagList: [],
      activeItem: {},
    });
    const router = useRouter();
    // contactApi.getTag('', {}).then(list => {
    //   list?.forEach(item => {
    //     state.TagList.push({ value: item.tagCode, label: item.tagName });
    //   });
    // });

    const handleClickViewMember = record => {
      console.log('record', record);
      store.commit('common/setViewAgenda', record);
      // router 跳转到查看议程
      let id = record.id;
      router.push(`/dashboard/${id}`);
    };

    return {
      handleClickViewMember,
      gridRef,
      store,
      ...toRefs(state),
      items: [
        { key: 'title', label: '日程标题' },
        // {
        //   key: 'province',
        //   label: '省份',
        //   type: 'select',
        //   valueKey: 'itemNameCn',
        //   labelKey: 'itemNameCn',
        //   url: `${classifyApi.getTreeUrl}?code=CHINA_AREA`,
        //   linkedKey: 'city',
        // },

        // todo 这几个类型东洋提供接口
        {
          key: 'activityType',
          label: '活动类型',
          type: 'select',
          valueKey: 'id',
          labelKey: 'categoryName',
          url: `${classifyApi.getTreeUrl}?moduleCode=meeting`,
          linkedKey: 'topicType',
        },
        {
          key: 'topicType',
          label: '专题类型',
          type: 'select',
          valueKey: 'id',
          labelKey: 'categoryName',
          linkedKey: 'topicLabel',
        },
        {
          key: 'topicLabel',
          label: '专题标签',
          type: 'select',
          valueKey: 'id',
          labelKey: 'categoryName',
        },
        {
          key: 'auditStatus',
          label: '审核状态',
          type: 'select',
          dataset: [
            { value: '0', label: '未审核' },
            { value: '1', label: '审核通过' },
            { value: '2', label: '审核不通过' },
          ],
        },
        {
          key: 'publishStatus',
          label: '发布状态',
          type: 'select',
          dataset: [
            { value: '1', label: '已发布' },
            { value: '0', label: '未发布' },
          ],
        },
      ],
      columns: [
        { dataIndex: 'language', title: '语言', width: 120, ellipsis: true },
        { dataIndex: 'title', title: '标题', width: 120, ellipsis: true },
        {
          dataIndex: 'activityTypeName',
          title: '活动类型(一级)',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'topicTypeName',
          title: '专题类型(二级)',
          width: 180,
          ellipsis: true,
        },
        {
          dataIndex: 'topicLabelName',
          title: '专题标签(三级)',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'activity',
          title: '日期',
          width: 120,
          ellipsis: true,
        },
        // todo 活动时间字段后续更改
        {
          dataIndex: 'endTime',
          title: '活动时间',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'gender',
          title: '查看议程',
          width: 120,
          slots: { customRender: 'gender' },
          ellipsis: true,
        },
        {
          dataIndex: 'isLive',
          title: '是否有直播',
          width: 120,
          slots: { customRender: 'isLive' },
          ellipsis: true,
        },
        {
          dataIndex: 'liveId',
          title: '直播ID',
          width: 120,
          ellipsis: true,
        },
        {
          dataIndex: 'meetingFormat',
          title: '会议形式',
          width: 120,
          slots: { customRender: 'meetingFormat' },
          ellipsis: true,
        },

        {
          dataIndex: 'auditStatus',
          title: '审核状态',
          width: 120,
          slots: { customRender: 'auditStatus' },
          ellipsis: true,
        },
        {
          dataIndex: 'publishStatus',
          title: '发布状态',
          width: 120,
          slots: { customRender: 'publishStatus' },
          ellipsis: true,
        },
        {
          dataIndex: 'isHome',
          title: '是否上首页',
          width: 120,
          slots: { customRender: 'isHome' },
          ellipsis: true,
        },
        {
          key: 'id',
          title: '操作',
          fixed: 'right',
          width: 200,
          slots: { customRender: 'operation' },
        },
      ],
      btnOperation: [
        {
          type: 'add',
          permission: 'classify:add',
          fnClick: () => {
            state.visible = true;
            state.activeItem = {};
          },
        },

        {
          type: 'isPublish',
          label: '发布',
          icon: 'ExportOutlined',
          permission: 'agenda:publish',
          fnClick: () => {
            classifyApi.publish('', '').then(() => {
              message.success('发布成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      options: [
        {
          type: 'update',
          label: '编辑',
          permission: 'agenda:update',
          fnClick: record => {
            let id = record.id;
            classifyApi.getActivities('', id).then(res => {
              state.visible = true;
              state.activeItem = { ...res };
            });
          },
        },
        {
          type: 'view',
          label: '查看',
          icon: 'EyeTwoTone',
          permission: 'contact:view',
          fnClick: record => {
            // 这个状态控制
            // 点击查看时传一个查看的状态去弹窗，然后弹窗判断该状态是true，则disble input
            state.visible = true;
            state.activeItem = { ...record, disabled: true };
          },
        },

        {
          type: 'delete',
          permission: 'resources:delete',
          fnClick: ({ id }) => {
            classifyApi.delete('resources:delete', { id: id }).then(res => {
              if (res) {
                message.success('操作成功');
                gridRef.value.refreshGrid();
              }
            });
          },
        },

        // todo 通过 拒绝接口 未联调 auditStatus
        {
          type: 'pass',
          icon: 'CheckCircleOutlined',
          permission: 'classify:pass',
          label: '通过',
          show: record => {
            if (record.auditStatus === 0) {
              return true;
            } else {
              return false;
            }
          },
          fnClick: record => {
            let parmas = {
              id: record.id,
              auditStatus: 1,
            };
            classifyApi.auditSchedule('', parmas).then(() => {
              message.success('审核通过');
              gridRef.value.refreshGrid();
            });
          },
        },

        {
          type: 'refuse',
          icon: 'StopOutlined',
          permission: 'classify:refuse',
          label: '拒绝',
          show: record => {
            if (record.auditStatus === 0) {
              return true;
            } else {
              return false;
            }
          },
          fnClick: record => {
            let parmas = {
              id: record.id,
              auditStatus: 2,
            };
            classifyApi.auditSchedule('', parmas).then(() => {
              message.success('操作成功');
              gridRef.value.refreshGrid();
            });
          },
        },
      ],
      // todo 改为   contactApi.pageUrl,
      url: classifyApi.classifyUrl,
      gridHeight: document.body.clientHeight - 386,
      handleFnSearch: value => {
        const temp = Object.assign({}, value);
        // temp.tag = temp.tag?.join(',');
        state.search = temp;
      },
      handleFnOkByEdit: value => {
        let type = 'add';
        const { id } = value;
        id && (type = 'update');
        classifyApi[type](`stand:${type}`, value).then(() => {
          message.success('操作成功');
          state.visible = false;
          gridRef.value.refreshGrid();
        });
      },
    };
  },
};
</script>

<style lang="less" scoped>
.iss-main-grid {
  max-height: calc(100vh - 88px);
  overflow-y: auto;
}
.contact-page {
  & .title {
    .right {
      i {
        margin-left: 3px;
      }
    }
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
